/*
	Welcome, all classes are named with the BEM convention.
	Google it :-)
*/

@import "_normalize.scss";

$siteWidth: 740px;

.browsehappy {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

* {
	box-sizing: border-box;
}

html, body {
	padding: 0;
	margin: 0;
}

/* Space out content a bit */
body {
	font-family: 'Droid Serif', serif;
	font-display: swap;
	font-size: 20px;
	line-height: 1.58;
	color: #3A4145;
	overflow-y: scroll; // Avoid the page flickers when content goes from long to short.
}

a {
	color: #3A4145;
}

h1 {
	font-size: 48px;
    line-height: 1.187;
	margin: 75px 0 0.4em 0;
}
h2 {
	font-size: 27px;
}
h3 {
	margin-top: 0;
	font-size: 22px;
}

/* Custom page header */
.header {
	padding: 33px 0 1em;
	background: #F7F0E6;
	text-align: center;

	/* Make the masthead heading the same height as the navigation */
	h3 {
		margin-top: 0;
		margin-bottom: 0;
		line-height: 32px;
		font-size: 2em;
		text-transform: uppercase;
		text-align: center;
		.by {
			display: block;
			font-size: 0.3em;
			font-weight: normal;
			padding-top: 9px;
			padding-bottom: 10px;
			line-height: 1;
			&:after {
			  	top: 6px;
  				position: relative;
				border-bottom: 1px solid #A3A3A3;
				content: " ";
				display: block;
				width: 225px;
				margin: auto;
			}
		}
	}
}

@import "_menu.scss";
@import "_markdown.scss";

main {
	margin: 0 auto;
	padding: 0 1em;
	max-width: $siteWidth;
}

article,
.article {
	margin-bottom: 3em;
	.article__content {}

	img {
		width: 100%;
	}
	footer a {
		border-top: 1px dashed rgba(0,0,0,0.1);
		padding: 1em;
		display: block;
		width: 100%;
		text-align: center;
		color: #000;
	}
}
.article__header {
	margin-bottom: 2em;
	h1 {
		font-size: 2.4em;
		margin: 2em 0 18px 0;
	}
	h3 {
		color: #727272;
		margin-top: 0;
	}
}
.article__header__metadata {
	color: #ccc;
	font-size: 16px;
}

.website-intro {
	background: #e0d6c8;
	padding: 1px 40px 40px;
	* {
		margin-left: auto;
		margin-right: auto;
		width: $siteWidth;
	}
}

.post {
	border-bottom: #EBF2F6 1px solid;
	time {
		color: #8e8d8d;
		font-size: 15px;
	}
	&:after {
		display: block;
		content: "";
		width: 7px;
		height: 7px;
		border: #E7EEF2 1px solid;
		position: absolute;
		bottom: -5px;
		left: 50%;
		margin-left: -5px;
		background: #FFF;
		border-radius: 100%;
		box-shadow: #FFF 0 0 0 5px;
	}
}

/* Custom page footer */
.footer {
	padding-top: 19px;
	color: #777;
	border-top: 1px solid #e5e5e5;
	font-size: 14px;
}

.container-narrow > hr {
	margin: 30px 0;
}

.hljs {
	tab-size: 4;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
}
