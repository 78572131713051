/* Menu */

.menu {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	&__item {
		float: left;
		margin: 10px 0;
		&--active {
			&__link {
				color: #fff;
				background-color: rgba(255, 255, 255, 0.1);
				&:hover, &:focus {
					color: #fff;
					background-color: rgba(255, 255, 255, 0.1);
				}	
			}
		}
		&:after {
			border-bottom: 1px solid #A3A3A3;
			content: " ";
			display: block;
			width: 10%;
			margin: auto;
		}
		&:hover, &:focus {
			text-decoration: none;
			background-color: rgba(0, 0, 0, 0.08);
		}
		&__link {
			text-decoration: none;
			display: inline-block;
			padding: 10px 20px;
			text-align: center;
			font-size: 0.9em;
			&__description {
				font-size: 0.6em;
				clear: both;
				display: block;
			}
		}
	}
}